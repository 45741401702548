import {actionTypes} from "../constants/actionTypes";
import ApiService from "../../services/ApiService";
import RequestType from "../constants/requestTypes";
import apiRoutes from "../constants/apiRoutes";

const { auth } = actionTypes;

const login = payload => {
    return async dispatch => {
        if (payload.status === 200) {
            dispatch({
                type: auth.LOGIN_SUCCESS,
                payload: payload.token
            });
        } else {
            dispatch({
                type: auth.LOGIN_FAILED
            });
        }
    };
};

const verifyToken = () => {
    return async dispatch => {
        try {
            const response = await ApiService.performRequest(RequestType.GET, apiRoutes.auth.verifyToken);

            if (response.success) {
                dispatch({
                    type: actionTypes.auth.VERIFY_TOKEN_SUCCESS
                });
            }

            return response;
        } catch (error) {
            dispatch({
                type: actionTypes.auth.VERIFY_TOKEN_FAILED
            });
        }
    }
};

const logout = (message = '') => {
    return async dispatch => {
        try {
            dispatch({
                type: auth.LOGOUT,
                payload: message
            });
        } catch (error) {
            // console.log(error);
        }
    }
};

export default {
    login,
    verifyToken,
    logout
};