import {actionTypes} from '../constants/actionTypes';

const {company} = actionTypes;

const initialState = {
    cwCompanies: [],
    getCwCompaniesError: '',
    exactCompanies: [],
    getExactCompaniesError: '',
    linkCompaniesError: '',
    connectWiseCompanies: [],
    companyWizard: {
        agreements: [],
        azurePlan: null,
        domains: [],
        sslCertificates: [],
        unlinkedAgreements: [],
        unlinkedDomains: [],
        unlinkedSslCertificates: [],
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case company.GET_CW_COMPANIES_SUCCESS:
            return {
                ...state,
                cwCompanies: action.payload
            };
        case company.GET_CW_COMPANIES_FAILED:
            return {
                ...state,
                cwCompanies: [],
                getCwCompaniesError: 'De Connectwise Companies konden niet worden geladen...'
            };
        case company.GET_EXACT_COMPANIES_SUCCESS:
            return {
                ...state,
                exactCompanies: action.payload
            };
        case company.GET_EXACT_COMPANIES_FAILED:
            return {
                ...state,
                exactCompanies: [],
                getExactCompaniesError: 'De Exact accounts konden niet worden geladen...'
            };
        case company.LINK_COMPANIES_SUCCESS:
            return {
                ...state,
                cwCompanies: action.payload.cwCompanies,
                exactCompanies: action.payload.exactCompanies,
            };
        case company.GET_ALL_CONNECTWISE_COMPANIES:
            return {
                ...state,
                connectWiseCompanies: action.payload
            };
        case company.LINK_COMPANIES_FAILED:
            return {
                ...state,
                linkCompaniesError: 'De geselcteerde companies konden niet met elkaar worden gematched...'
            };

        case company.GET_COMPANY_WIZARD_SUCCESS:
            return {
                ...state,
                companyWizard: {
                    ...state.companyWizard,
                    ...action.payload
                }
            };
        case company.GET_COMPANY_WIZARD_FAILED:
            return {
                ...state,
                companyWizard: initialState.companyWizard
            };
        case company.GET_COMPANY_WIZARD_AZURE_PLAN_SUCCESS:
            return {
                ...state,
                companyWizard: {
                    ...state.companyWizard,
                    azurePlan: {
                        ...state.companyWizard.azurePlan,
                        azure_plan: action.payload
                    }
                }
            };
        case company.POST_COMPANY_WIZARD_AZURE_PLAN_ADDITON_SUCCESS:
            return {
                ...state,
                companyWizard: {
                    ...state.companyWizard,
                    azurePlan: {
                        ...state.companyWizard.azurePlan,
                        azure_plan: action.payload
                    }
                }
            };
        default:
            return state;
    }
}