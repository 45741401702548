import {combineReducers} from "redux";

import auth from './authenticationReducer';
import company from './companyReducer';
import domain from './domainReducer';
import actionLog from './actionLogReducer';
import sslCertificates from "./sslCertificatesReducer";
import vwCustomerMappingToolReducer from "./vwMappingToolReducer";


export default combineReducers({
    auth,
    companies: company,
    domain,
    actionLog,
    sslCertificates,
    vwCustomerMappingToolReducer
});