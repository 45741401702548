import axios from 'axios';
import requestTypes from '../redux/constants/requestTypes';
import apiRoutes from '../redux/constants/apiRoutes';
import AuthenticationService from './AuthenticationService';

export default class ApiService {
    static performRequest = async (requestType, url, data = null, options = []) => {
        const token = AuthenticationService.getToken();
        let requestUrl = apiRoutes.rootUrl + url;
        let response;

        try {
            switch (requestType) {
                case requestTypes.GET:
                    response = await this.getRequest(requestUrl, token);
                    break;
                case requestTypes.POST:
                    response = await this.postRequest(requestUrl, token, data);
                    break;
                case requestTypes.PUT:
                    response = await this.putRequest(requestUrl, token, data);
                    break;
                case requestTypes.DELETE:
                    response = await this.deleteRequest(requestUrl, token);
                    break;
                case requestTypes.PATCH:
                    response = await this.patchRequest(requestUrl, token, data);
                    break;
            }

            console.log('API RESPONSE', response);

            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                AuthenticationService.logout('You were logged out after an unauthorized request...');
            }
            throw error;
        }
    };

    static getRequest = async (url, token) => {
        return await axios.get(url, {headers: {'Authorization' : `Bearer ${token}`}});
    };

    static putRequest = async (url, token, data) => {
        return await axios.put(url, data, {headers: {'Authorization' : `Bearer ${token}`}});
    };

    static deleteRequest = async (url, token) => {
        return await axios.delete(url, {headers: {'Authorization' : `Bearer ${token}`}});
    };

    static postRequest = async (url, token, data) => {
        if (data instanceof File) {
            let formData = new FormData();
            formData.append('file', data);
            return await axios.post(url, formData, {headers: {
                    'Authorization' : `Bearer ${token}`,
                    'Content-Type' : 'multipart/form-data'
                }});
        } else {
            return await axios.post(url, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
        }
    };

    static patchRequest = async (url, token, data) => {
        return await axios.patch(url, data, {headers: {'Authorization' : `Bearer ${token}`}})
    }
}