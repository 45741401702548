import {actionTypes} from '../constants/actionTypes';

const {domain} = actionTypes;

const initialState = {
  unlinkedDomains: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case domain.GET_DOMAIN_WIZARD_UNLINKED_DOMAINS:
      return {
        ...state,
        unlinkedDomains: action.payload
      };
    case domain.RESET_DOMAIN_WIZARD_UNLINKED_DOMAINS:
      return {
        ...state,
        unlinkedDomains: []
      };
    case domain.LINK_VERSIO_DOMAINS:
      return {
        ...state,
        unlinkedDomains: action.payload
      };
    case domain.GET_VERSIO_DOMAINS:
      return {
        ...state,
        versioDomains: action.payload
      };
    // case domain.GET_CONNECTWISE_CONFIGURATIONS:
    //   return {
    //     ...state,
    //     cwCompanies: [],
    //     connectWiseConfigurations: action.payload
    //   };
    // case domain.POST_CONNECTWISE_CONFIGURATIONS:
    //   return {
    //     ...state,
    //     connectWiseConfigurations: [...state.connectWiseConfigurations, ...action.payload.map(config => {
    //       return config.success ? config.configuration : null;
    //     })]
    //   };
    default:
      return state;
  }
}