import React from 'react';
import * as PropTypes from 'prop-types';

const Content = (props) => {
    return (
        <div className={'container-fluid'}>
            {props.children}
        </div>
    );
};

Content.propTypes = {
    children: PropTypes.object
};

export default Content;