import React from 'react';
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { connect } from "react-redux";

import Navigation from './Navigation';
import Content from './Content';
import routes from '../../routes';

const Layout = props => {
    const { auth } = props;

    return (
        <div style={{backgroundColor: 'rgb(247, 247, 250)', height: '100vh'}}>
            {!auth.isLoggedIn ?
                <Redirect to={'/login'}/>
                :
                <>
                    <Navigation routes={routes} />
                </>
            }

            <Switch>
                {routes.map((route, i) => {
                    return (
                        <Route exact={route.exact} key={i} path={route.path}>
                            <Content>
                                {<route.component {...route} identifier={i} />}
                            </Content>
                        </Route>
                    )
                })}
            </Switch>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

export default connect(mapStateToProps)(Layout);