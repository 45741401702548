import {actionTypes} from '../constants/actionTypes';

const {auth} = actionTypes;

const initialState = {
    isLoggedIn: localStorage.getItem('token'),
    loginMessage: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case auth.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true
            };
        case auth.LOGIN_FAILED:
            return {
                ...state,
                isLoggedIn: false,
                loginMessage: 'Unauthorized login request'
            };
        case auth.VERIFY_TOKEN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true
            };
        case auth.VERIFY_TOKEN_FAILED:
            return {
                ...state,
                isLoggedIn: false,
                loginMessage: 'Unauthorized login request'
            };
        case auth.LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                loginMessage: action.payload
            };
        default:
            return state;
    }
}