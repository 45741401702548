import React from 'react';
import axios from 'axios';
import apiRoutes from "../redux/constants/apiRoutes";
import store from '../redux/store';
import authenticationActions from "../redux/actions/authenticationActions";

export default class AuthenticationService {

    static login = async (payload) => {
        let requestUrl = apiRoutes.rootUrl + apiRoutes.auth.login;
        let response = await axios.get(requestUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + payload.accessToken
            }
        });

        if (response.status === 200) {
            AuthenticationService.setToken(payload.accessToken);
        }

        return response;
    };

    static logout = (message = '') => {
        localStorage.removeItem('token');
        store.dispatch(authenticationActions.logout(message));
    };

    static setToken = (token) => {
        localStorage.setItem('token', token);
    };

    static getToken = () => {
        return localStorage.getItem('token');
    };
}
