const apiRoutes = {
    rootUrl: process.env.REACT_APP_API_URL,

    auth: {
        login: 'login',
        verifyToken: 'verify_token'
    },

    company: {
        getCwCompanies: 'companies/cwcompanies',
        getExactCompanies: 'companies/exactcompanies',
        getAllConnectWiseCompanies: 'companies/connectwise',

        getCompanyWizardOverview: 'company_wizard/overview/{companyId}',
        getCompanyAzurePlan: 'company_wizard/{companyId}/azure_plan',
        activateAzurePlan: 'company_wizard/{companyId}/azure_plan/activate',
        billAzurePlanAddition: 'company_wizard/{companyId}/azure_plan/bill_addition',
        syncAzurePlan: 'company_wizard/{companyId}/azure_plan/{azurePlanId}/sync'
    },

    domain: {
        getDomainWizardUnlinkedDomains: 'domain_wizard/versio_domains',
        getAgreementsByCompanyId: 'domain_wizard/agreements/{companyId}',
        getVersioDomains: 'domains',
        getConnectWiseConfigurations: 'configurations',
        linkVersioDomains: 'domain_wizard/versio_domains'
    },

    actionLog: {
        list: 'action_logs'
    },

    integration: {
        list: 'integrations',
        post: 'integration',
        patch: 'integration/{id}'
    },
    sslCertificates: {
        getSslCertificates: 'ssl',
        createConfigurationforSsl: 'ssl/configuration'
    },

    voiceworksMappingTool: {
        overviewVWCustomer: 'voice-works-customer',
        overviewExactCompany: 'exact-company',
        get: '',
        post: 'map/voiceworks-customers/exact-companies',
        patch: '',
        delete: '',
    },

    
};

export default apiRoutes;