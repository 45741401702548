/* eslint-disable import/no-anonymous-default-export */
import {actionTypes} from '../constants/actionTypes';

const { voiceworksMappingTool } = actionTypes;

const initialState = {
    voiceworksCustomers: [],
    exactCompaines: []
}

export default (state = initialState, action) => {
    switch (action.type) {

        case voiceworksMappingTool.GET_VOICEWORKS_CUSTOMERS_SUCCESS:
            return {
                ...state,
                voiceworksCustomers: action.payload
            }
            
            case voiceworksMappingTool.GET_EXACT_COMPANIES_SUCCESS:
            return {
                ...state,
                exactCompaines: action.payload
            }

        case voiceworksMappingTool.POST_VOICEWORKS_MAPPING_TOOL_SUCCESS:
            return {
                ...state
            }

        // case voiceworksMappingTool.PATCH_VOICEWORKS_MAPPING_TOOL_SUCCESS:
        //     return {
        //         voiceworksMappingTool: [...state.voiceworksMappingTool.filter(({ id }) => id !== action?.payload?.id), action?.payload]
        //     }

        // case voiceworksMappingTool.DELETE_VOICEWORKS_MAPPING_TOOL_SUCCESS:
        //     return {
        //         voiceworksMappingTool: state.voiceworksMappingTool.filter(({ id }) => id !== action?.payload?.id)
        //     }

        default:
            return state;
    }
}