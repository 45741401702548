import React from 'react';
import Loadable from 'react-loadable';

function Loading() {
    return <div>Laden...</div>;
}

const Home = Loadable({
    loader: () => import('./containers/home/Home'),
    loading: Loading
});

// const Company = Loadable({
//     loader: () => import('./containers/company/CompanyLinkPage'),
//     loading: Loading
// });

const CompanyList = Loadable({
    loader: () => import('./containers/connectWiseLinking/CompanyList'),
    loading: Loading
});

const CompanyLinkingPage = Loadable({
    loader: () => import('./containers/connectWiseLinking/ConnectWiseLinking'),
    loading: Loading
});

const Versio = Loadable({
    loader: () => import('./containers/versio/Versio'),
    loading: Loading
});

const AuthenticateIntegrations = Loadable({
    loader: () => import('./containers/auth/AuthenticateIntegrations'),
    loading: Loading
});

const Login = Loadable({
    loader: () => import('./containers/auth/Login'),
    loading: Loading
})

const VWMappingTool = Loadable({
    loader: () => import('./containers/vwMappingTool'),
    loading: Loading
});

const routes = [
    {
        name: 'Home',
        path: '/',
        component: Home,
        exact: true,
        nav: false
    },
    // {
    //     name: 'Companies',
    //     path: '/companies',
    //     component: Company,
    //     exact: false,
    //     nav: true
    // },
    {
        name: 'Companies',
        path: '/companies',
        component: CompanyList,
        exact: true,
        nav: true
    },
    {
        name: 'Company assets linking',
        path: '/companies/:companyId',
        component: CompanyLinkingPage,
        exact: true,
        nav: false
    },
    {
        name: 'Versio',
        path: '/versio',
        component: Versio,
        exact: false,
        nav: true
    },
    {
        name: 'Auth Integrations',
        path: '/auth/integrations',
        component: AuthenticateIntegrations,
        exact: false,
        nav: true
    },
    {
        name: 'Voiceworks customer',
        path: '/vwmappingtool',
        component: VWMappingTool,
        exact: false,
        nav: true
    },
    {
        name: 'Login',
        path: '/login',
        component: Login,
        exact: false,
        nav: false
    }
];

export default routes;