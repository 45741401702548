const actionTypes = {
    auth: {
        LOGIN_SUCCESS: "LOGIN_SUCCESS",
        LOGIN_FAILED: "LOGIN_FAILED",

        VERIFY_TOKEN_SUCCESS: "VERIFY_TOKEN_SUCCESS",
        VERIFY_TOKEN_FAILED: "VERIFY_TOKEN_FAILED",

        LOGOUT: "LOGOUT"
    },

    company: {
        GET_CW_COMPANIES_SUCCESS: "GET_CW_COMPANIES_SUCCESS",
        GET_CW_COMPANIES_FAILED: "GET_CW_COMPANIES_FAILED",

        GET_EXACT_COMPANIES_SUCCESS: "GET_EXACT_COMPANIES_SUCCESS",
        GET_EXACT_COMPANIES_FAILED: "GET_EXACT_COMPANIES_FAILED",

        GET_ALL_CONNECTWISE_COMPANIES: "GET_ALL_CONNECTWISE_COMPANIES",

        LINK_COMPANIES_SUCCESS: "LINK_COMPANIES_SUCCESS",
        LINK_COMPANIES_FAILED: "LINK_COMPANIES_FAILED",

        GET_COMPANY_WIZARD_SUCCESS: "GET_COMPANY_WIZARD_SUCCESS",
        GET_COMPANY_WIZARD_FAILED: "GET_COMPANY_WIZARD_FAILED",

        GET_COMPANY_WIZARD_AZURE_PLAN_SUCCESS: "GET_COMPANY_WIZARD_AZURE_PLAN_SUCCESS",
        GET_COMPANy_WIZARD_AZURE_PLAN_FAILED: "GET_COMPANy_WIZARD_AZURE_PLAN_FAILED",

        POST_COMPANY_WIZARD_AZURE_PLAN_ADDITON_SUCCESS: "POST_COMPANY_WIZARD_AZURE_PLAN_ADDITON_SUCCESS",
        GET_COMPANY_WIZARD_SYNC_AZUREPLAN: "GET_COMPANY_WIZARD_SYNC_AZUREPLAN"
    },

    domain: {
        GET_DOMAIN_WIZARD_UNLINKED_DOMAINS: "GET_DOMAIN_WIZARD_UNLINKED_DOMAINS",
        GET_DOMAIN_WIZARD_AGREEMENTS_BY_COMPANY: "GET_DOMAIN_WIZARD_AGREEMENTS_BY_COMPANY",
        GET_VERSIO_DOMAINS: "GET_VERSIO_DOMAINS",
        GET_CONNECTWISE_CONFIGURATIONS: "GET_CONNECTWISE_CONFIGURATIONS",
        POST_CONNECTWISE_CONFIGURATIONS: "POST_CONNECTWISE_CONFIGURATIONS",
        LINK_VERSIO_DOMAINS: "LINK_VERSIO_DOMAINS",
        LINK_CONNECTWISE_CONFIGURATIONS: "LINK_CONNECTWISE_CONFIGURATIONS",
        RESET_DOMAIN_WIZARD_UNLINKED_DOMAINS: "RESET_DOMAIN_WIZARD_UNLINKED_DOMAINS"
    },

    actionLog: {
        GET_ACTIONLOG_LIST_SUCCESS: "GET_ACTIONLOG_LIST_SUCCESS"
    },
    sslCertificates: {
        GET_SSLCERTIFICATES_SUCCESS : "GET_SSLCERTIFICATES_SUCCESS",
        GET_SSLCERTIFICATES_FAILED : "GET_SSLCERTIFICATES_FAILED",
        CREATE_CONFIGURATION_SUCCES : 'CREATE_CONFIGURATION_SUCCES',
        CREATE_CONFIGURATION_FAILED : 'CREATE_CONFIGURATION_FAILED',
    },

    voiceworksMappingTool: {
        GET_VOICEWORKS_CUSTOMERS_SUCCESS : "GET_VOICEWORKS_CUSTOMERS_SUCCESS",
        GET_EXACT_COMPANIES_SUCCESS : "GET_EXACT_COMPANIES_SUCCESS",
        GET_VOICEWORKS_MAPPING_TOOL_FAILED : "GET_VOICEWORKS_MAPPING_TOOL_FAILED",
        POST_VOICEWORKS_CUSTOMERS_AND_COMPANIES_MAPPING_TOOL_SUCCESS : "POST_VOICEWORKS_CUSTOMERS_AND_COMPANIES_MAPPING_TOOL_SUCCESS",
    },


};

export {
    actionTypes
};