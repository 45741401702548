import {
  PublicClientApplication,
} from "@azure/msal-browser";
import {LogLevel} from "@azure/msal-browser";

const msal = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_OFFICE_CLIENTID,
    redirectUri: process.env.REACT_APP_OFFICE_REDIRECT_URL
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
});
var account = null;
var loginRequest = {
  scopes: ['user.read'],
  prompt: "select_account",
};
var loginRedirectRequest = {
  ...loginRequest,
  redirectStartPage: window.location.href,
};
const OfficeService = {
  login: async (signInType) => {
    if (signInType === "loginPopup") {
      return msal
        .loginPopup(loginRequest)
        .then(response => {
          if(response !== null && response.account !==null) {
            console.log('auth response', response)
            account = response.account;
            return response;
          } else {
            account = OfficeService.getAccount();
          }

          return response;
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (signInType === "loginRedirect") {
      return msal.loginRedirect(loginRedirectRequest).then(res => {});
    }
  },
  logout: () => {},
  getAccount: () => {
    // console.log(`loadAuthModule`);
    const currentAccounts = msal.getAllAccounts();
    if (currentAccounts === null) {
      // console.log("No accounts detected");
      return undefined;
    }

    if (currentAccounts.length > 1) {
      // console.log(
      //   "Multiple accounts detected, need to add choose account code.",
      //   currentAccounts
      // );
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  }
};

export default OfficeService;