import {actionTypes} from '../constants/actionTypes';

const {actionLog} = actionTypes;

const initialState = {
  actionsLastHour: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionLog.GET_ACTIONLOG_LIST_SUCCESS:
      return {
        ...state,
        actionsLastHour: action.payload
      };
    default:
      return state;
  }
}