import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import JWT from "jwt-decode";

import {
  Button,
  ButtonGroup,
  Nav,
  Navbar,
  OverlayTrigger,
  Popover
} from "react-bootstrap";

import {
  FaUser
} from "react-icons/fa";

import authenticationActions from "../../redux/actions/authenticationActions";
import AuthenticationService from "../../services/AuthenticationService";
import OfficeService from "../../services/OfficeService";

const Navigation = props => {
    useEffect(() => {
        // props.authenticationActions.verifyToken();
    });

    return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/">
          IT Sync
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={'mr-auto'}>
            {props.routes.map((route, i) => {
              return route.nav ?
                (<Nav.Link href={route.path} key={i}>{route.name}</Nav.Link>)
                :
                null;
            })}
          </Nav>
          <ButtonGroup>
            <OverlayTrigger
              trigger={['focus']}
              key={'loggedInAs'}
              placement={'bottom'}
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <Popover.Title as="h3">{JWT(localStorage.getItem('token')).firstName + ' ' + JWT(localStorage.getItem('token')).lastName}</Popover.Title>
                  <Popover.Content>
                    <ul>
                      {
                        <li>{JWT(localStorage.getItem('token')).firstName + ' ' + JWT(localStorage.getItem('token')).lastName}</li>
                      }
                    </ul>
                  </Popover.Content>
                </Popover>
              }
            >
              <Button
                size={'sm'}
                variant="secondary"
              >
                <FaUser />
              </Button>
            </OverlayTrigger>
            <Button
              size={'xs'}
              variant={'secondary'}
              type="button"
              onClick={() => {
                AuthenticationService.logout();
                props.authenticationActions.logout('You have been successfully logged out!');
              }}
            >
              Logout
            </Button>
          </ButtonGroup>
        </Navbar.Collapse>
      </Navbar>
    );
};

Navigation.propTypes = {
    routes: PropTypes.array
};

const mapDispatchToProps = dispatch => {
    return {
        authenticationActions: bindActionCreators(authenticationActions, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(Navigation);
